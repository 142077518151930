import React, { useState, useEffect, createContext, useContext } from "react";
import Navbar from "../components/Navbar";
import AddNewCategory from "../components/AddNewCategory";
import UpdateCat from "../components/UpdateCat";
export const CategoryContext = createContext();
function AddCategory() {
  return (
    <div className="grid grid-cols-1 p-2 gap-10 md:grid-cols-1 container ml-auto mr-auto text-center font-sans mt-auto mb-auto">
      <Navbar />
      <AddNewCategory></AddNewCategory>
    </div>
  );
}

export default AddCategory;
