import React from "react";
import { Formik } from "formik";
import axios from "axios";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
function AddNewCategory() {
  return (
    <div>
      <div className="container md:w-1/2 md:ml-auto md:mr-auto mb-12 glassmorphism rounded-2xl mt-10">
        <Formik
          className="flex-none"
          initialValues={{ name: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Kategori ismi girmelisiniz.";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            console.log(values);
            const token = Cookies.get("token");
            axios
              .post(
                "https://api.kahvecikolataistanbul.com/categories",
                values,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                toast("Kategori Eklendi");
              });
            setSubmitting(false);
            resetForm();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form
              className="text-form-title font-semibold text-lg"
              onSubmit={handleSubmit}
            >
              <div className="block mt-5 mb-5 text-form-title">
                <label htmlFor="name">Kategori Ekle</label>
                <input
                  className="block ml-auto mr-auto rounded-lg border-transparent flex-1 appearance-none border mt-5 mb-5 border-gray-300 w-11/12 py-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </div>
              {touched.name && errors.name && (
                <div className="font-extralight mt-2 mb-2 left-[80px]  text-red-600">
                  {errors.name}
                </div>
              )}
              <button
                type="submit"
                disabled={isSubmitting}
                className="py-2 w-20 px-4 mb-5 bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
              >
                Ekle
              </button>
            </form>
          )}
        </Formik>
      </div>
      <ToastContainer></ToastContainer>
    </div>
  );
}

export default AddNewCategory;
