import React from "react";
import AddMenu from "../components/AddMenu";
import UpdateMenu from "../components/UpdateMenu";
import Navbar from "../components/Navbar";

function UpdateItem() {
  return (
    <div className="grid grid-cols-1 p-2 gap-10 md:grid-cols-1 container ml-auto mr-auto text-center font-sans mt-auto mb-auto">
      <Navbar></Navbar>
      <UpdateMenu></UpdateMenu>
    </div>
  );
}

export default UpdateItem;
