import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="container font-sans ">
      <nav className="rounded-lg mt-5 text-center glassmorphism text-form-title">
        <div className="px-8 mx-auto">
          <div className="flex items-center justify-center h-16">
            <div className="flex items-center">
              <div className="md:block">
                <div className="flex items-baseline md:ml-10 space-x-4">
                  <Link
                    className="text-form-title hover:text-form-title-hover  px-3 py-2 rounded-md text-sm md:text-base font-bold"
                    to="/add"
                  >
                    Ürün Ekle
                  </Link>
                  <Link
                    className="text-form-title hover:text-form-title-hover  px-3 py-2 rounded-md text-sm md:text-base font-bold"
                    to="/update"
                  >
                    Ürün Güncelle
                  </Link>
                  <Link
                    className="text-form-title hover:text-form-title-hover  px-3 py-2 rounded-md text-sm md:text-base font-bold"
                    to="/add-category"
                  >
                    Kategori Ekle
                  </Link>
                  <Link
                    className="text-form-title hover:text-form-title-hover  px-3 py-2 rounded-md text-sm md:text-base font-bold"
                    to="/update-category"
                  >
                    Kategori Güncelle
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobil menü için div'ler */}
      </nav>
    </div>
  );
};

export default Navbar;
