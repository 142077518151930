import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { RenderRoutes } from "../structure/RenderNavigation";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
export const AuthContext = createContext();

export const AuthData = () => useContext(AuthContext);
export const AuthWrapper = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    name: "",
    isAuthenticated: false,
  });
  const login = (userName, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post("https://api.kahvecikolataistanbul.com/login", {
          uname: userName,
          pwd: password,
        })
        .then((response) => {
          const token = response.data.data;
          Cookies.set("token", token, { secure: true });
          setUser({ name: userName, isAuthenticated: true });
          const getToken = Cookies.get("token");
          if (getToken) {
            Cookies.set("isAuth", true, { secure: true });
          } else {
            Cookies.set("isAuth", false, { secure: false });
          }
          navigate("/add");
        })
        .catch((err) => reject(err.response.data.data));
    });
  };
  const logout = () => {
    axios
      .post("https://api.kahvecikolataistanbul.com/logout")
      .then((response) => console.log(response))
      .catch((err) => console.log(err));
    setUser({ ...user, isAuthenticated: false });
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      <RenderRoutes></RenderRoutes>
    </AuthContext.Provider>
  );
};
