import React, { useState, useEffect } from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { BsBasket3, BsBasket3Fill } from "react-icons/bs";
import { MdDelete, MdOutlineShoppingBasket } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "../ui/Spinner";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
function UpdateMenu() {
  const [menu, setMenu] = useState([]);
  const [isMenuLoading, setIsMenuLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  let navigate = useNavigate();
  const token = Cookies.get("token");
  //Menüyü listeleme ve img yolunu atama
  const getMenu = () => {
    axios
      .get("https://api.kahvecikolataistanbul.com/menu")
      .then(
        (response) => {
          if (response.data.statusText === "error")
            return alert(response.data.data);
          var menuData = [];
          response.data.data.forEach((item, index) => {
            const imgUrlArray = [response.data.data[index].img_path.split("/")];
            const imgUrl =
              "https://api.kahvecikolataistanbul.com/public/images/" +
              imgUrlArray[0][imgUrlArray[0].length - 1];
            var customItem = {
              ...item,
              img_url: imgUrl,
            };
            menuData.push(customItem);
          });
          setMenu(menuData);
          setIsMenuLoading(true);
          menuData.length < 1 ? setIsEmpty(true) : setIsEmpty(false);
        },
        (error) => {}
      )
      .catch((err) => toast(err));
  };

  //Sayfa sıfırlandığında menüdeki ürünleri listele
  useEffect(() => {
    getMenu();
  }, []);

  //Silme İşlemini gerçekleştir
  const handleDelete = (id) => {
    axios
      .delete(`https://api.kahvecikolataistanbul.com/products/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.statusText === "error")
          return alert(response.data.data);
        getMenu();
        toast("Ürün silindi.");
      })
      .catch((err) => {
        if (err.response.status === 401) {
          Cookies.remove("token");
          Cookies.remove("isAuth");
          navigate("/login");
        } else {
          toast(err.response.data.statusText);
        }
      });
  };

  //Tüm stokları sıfırla
  const handleAllStocks = (menu) => {
    menu.map((item) => {
      const formData = new FormData();
      formData.append("name", item.name);
      formData.append("content", item.content);
      formData.append("price", item.price);
      formData.append("category", item.categoryID);
      formData.append("stok", 1);
      axios
        .put(
          `https://api.kahvecikolataistanbul.com/products/${item.id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          getMenu();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Cookies.remove("token");
            Cookies.remove("isAuth");
            navigate("/login");
          } else {
            toast(err.response.data.statusText);
          }
        });
    });
  };

  //Stok bitirme, alma
  const handleStock = (
    id,
    name,
    content,
    price,
    categoryID,
    categoryName,
    img_path,
    stok
  ) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("content", content);
    formData.append("price", price);
    formData.append("category", categoryID);
    formData.append("stok", stok ? 0 : 1);
    axios
      .put(`https://api.kahvecikolataistanbul.com/products/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        toast("Stok Değişli.");
        getMenu();
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          Cookies.remove("token");
          Cookies.remove("isAuth");
          navigate("/login");
        } else {
          toast(err.response.data.statusText);
        }
      });
  };

  //Update işlemi update-form'a alttaki propları navigate aracılığı ile göndererek orda güncelleme işlemini yaptırıyor.
  const handleUpdate = (
    id,
    name,
    content,
    price,
    categoryID,
    categoryName,
    img_path,
    stok
  ) => {
    navigate("/update-form", {
      state: {
        mealId: id,
        mealName: name,
        mealContent: content,
        mealPrice: price,
        mealCatId: categoryID,
        mealCatName: categoryName,
        mealImg: img_path,
        mealStok: stok,
      },
    });
  };

  return (
    <div>
      <div className="container p-5 text-form-title glassmorphism rounded-2xl mt-10 mb-auto">
        {!isEmpty ? (
          <div className="">
            <button
              type="button"
              className="py-2 px-8 flex mb-5 justify-center items-center bg-purple-900  hover:bg-purple-700 text-white md:w-1/2 ml-auto mr-auto transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
              onClick={() => handleAllStocks(menu)}
            >
              <BsBasket3 className="mr-2" />
              Tüm Stokları Sıfırla
            </button>
            <div className=" hidden md:flex  bg-white shadow-custom mt-5 mb-5 rounded-lg justify-between items-center">
              <div className="md:w-[150px] ml-4 overflow-wrap md:text-xl font-bold">
                Resim
              </div>
              <div className="w-1/4 overflow-wrap md:text-xl font-bold">
                Ürün
              </div>
              <div className="w-1/4 overflow-wrap md:text-xl font-bold">
                İçerik
              </div>
              <div className="w-1/4 overflow-wrap md:text-xl font-bold">
                Kategori
              </div>
              <div className="w-1/4 overflow-wrap md:text-xl font-bold">
                Fiyat
              </div>
              <div className="w-[420px] overflow-wrap md:text-xl font-bold"></div>
            </div>
            {isMenuLoading ? (
              menu.map((meal, key) => (
                <div
                  key={key}
                  className="grid-cols-1 mt-5 mb-5 items-center shadow-custom	 rounded-xl md:flex bg-white p-5 border-0 justify-between"
                >
                  <div className="pointer overflow-wrap h-[200px] md:w-[150px] md:h-[150px]">
                    <img
                      key={new Date()}
                      className="object-cover w-full h-full rounded-lg"
                      src={meal.img_url}
                      alt=""
                    />
                  </div>
                  <div className="pointer border-slate-300  border-b-2 border-t-2 border-r-2 border-l-2  md:border-none  mt-2 mb-2  overflow-wrap  w-full md:w-1/4 text-wrap text-xl md:text-2xl font-bold ">
                    <h2 className="font-medium border-b-2 border-slate-300 md:hidden">
                      Ürün
                    </h2>
                    {meal.name}
                  </div>
                  <div
                    className="pointer inline-block mt-2 mb-2 border-slate-300  border-b-2 border-t-2 border-r-2 border-l-2   md:border-none  overflow-wrap break-words whitespace-normal w-full object-center md:w-1/4 text-lg md:text-2xl"
                    style={{ wordBreak: "break-all" }}
                  >
                    <h2 className="font-medium border-b-2 border-slate-300 md:hidden mb-2">
                      İçerik
                    </h2>

                    {meal.content}
                  </div>
                  <div className="pointer overflow-wrap  border-slate-300  border-b-2 border-t-2 border-r-2 border-l-2 mt-2 mb-2 md:border-none   md:w-1/4 text-wrap text-lg md:text-2xl">
                    <h2 className="font-medium border-b-2 border-slate-300 md:hidden mb-2">
                      Kategori
                    </h2>
                    {meal.categoryName}
                  </div>
                  <div className="pointer overflow-wrap border-slate-300  border-b-2 border-t-2 border-r-2 border-l-2 mt-4 mb-4  md:border-none md:w-1/4 text-wrap text-lg md:text-2xl">
                    <h2 className="font-medium border-b-2 border-slate-300 md:hidden mb-2">
                      Ücret
                    </h2>
                    {meal.price}
                  </div>
                  <div className="pointer flex gap-y-3 md:gap-y-3  gap-x-3 flex-wrap ">
                    {meal.stok === 1 ? (
                      <button
                        type="button"
                        className="py-2 px-8  flex justify-center items-center bg-green-500 hover:bg-green-700 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                        onClick={() =>
                          handleStock(
                            meal.id,
                            meal.name,
                            meal.content,
                            meal.price,
                            meal.categoryId,
                            meal.categoryName,
                            meal.img_path,
                            meal.stok
                          )
                        }
                      >
                        <MdOutlineShoppingBasket
                          size={25}
                          className="text-lg mr-2"
                        />
                        Stok Bitir
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="py-2 px-8 flex justify-center items-center bg-red-500 hover:bg-red-700 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                        onClick={() =>
                          handleStock(
                            meal.id,
                            meal.name,
                            meal.content,
                            meal.price,
                            meal.categoryId,
                            meal.categoryName,
                            meal.img_path,
                            meal.stok
                          )
                        }
                      >
                        <MdOutlineShoppingBasket
                          size={25}
                          className="mr-2 text-lg"
                        />
                        Stok Al
                      </button>
                    )}

                    <button
                      type="button"
                      className="py-2 px-4 flex justify-center items-center bg-green-500 hover:bg-green-700 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                      onClick={() =>
                        handleUpdate(
                          meal.id,
                          meal.name,
                          meal.content,
                          meal.price,
                          meal.categoryId,
                          meal.categoryName,
                          meal.img_path,
                          meal.stok
                        )
                      }
                    >
                      <BsFillPencilFill className="mr-2" />
                      Güncelle
                    </button>
                    <button
                      type="button"
                      className="py-2 px-4 flex justify-center items-center   bg-red-600 hover:bg-red-700 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                      onClick={() => handleDelete(meal.id)}
                    >
                      <MdDelete className="mr-2" />
                      Sil
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <Spinner text={"Yükleniyor"}></Spinner>
            )}
          </div>
        ) : (
          <div>
            Ürün yok,Lütfen Ürün Ekleyiniz <br /> <br />
            <button
              type="submit"
              onClick={() => navigate("/add")}
              className="py-2 w-28 px-4 mb-5 bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
            >
              Ürün Ekle
            </button>
          </div>
        )}
      </div>
      <ToastContainer></ToastContainer>
    </div>
  );
}

export default UpdateMenu;
