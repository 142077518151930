import React, { useContext, useEffect, useReducer } from "react";
import { TbBrandOauth } from "react-icons/tb";
import { CiUnlock } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { AuthData } from "../context/AuthContext";
import { AuthContext } from "../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Login() {
  const navigate = useNavigate();
  const { login } = AuthData();
  const { user } = useContext(AuthContext);

  const isAuth = user.isAuthenticated;
  const [formData, setFormData] = useReducer(
    (formData, newItem) => {
      return { ...formData, ...newItem };
    },
    { userName: "", password: "" }
  );
  const doLogin = async (event) => {
    event.preventDefault();
    login(formData.userName, formData.password)
      .then(() => navigate("/add"))
      .catch((err) => toast(err));
  };

  return (
    <div className="flex items-center justify-center h-screen p-1">
      <div className="flex flex-col mr-auto ml-auto font-sans  w-full max-w-md px-4 py-8 rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
        <div className="self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white">
          Panele Giriş Yap
        </div>
        <div className="mt-8">
          <form onSubmit={doLogin}>
            <div className="flex flex-col mb-2">
              <div className="flex relative ">
                <span className="rounded-l-md inline-flex items-center px-3 border-t bg-white border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm">
                  <TbBrandOauth />
                </span>
                <input
                  type="text"
                  id="sign-in-email"
                  className="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  placeholder="Kullanıcı Adı"
                  value={formData.userName}
                  onChange={(e) => setFormData({ userName: e.target.value })}
                />
              </div>
            </div>
            <div className="flex flex-col mb-6">
              <div className="flex relative ">
                <span className="rounded-l-md inline-flex items-center px-3 border-t bg-white border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm">
                  <CiUnlock />
                </span>
                <input
                  type="password"
                  id="sign-in-password"
                  className="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  placeholder="Şifre"
                  value={formData.password}
                  onChange={(e) => setFormData({ password: e.target.value })}
                />
              </div>
            </div>
            <div className="flex w-full">
              <button
                type="submit"
                className="py-2 px-4 bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
              >
                Giriş Yap
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;
