import axios from "axios";
import { Formik } from "formik";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";

function UpdateCatForm() {
  let location = useLocation();
  const updateCategory = location.state;
  let navigate = useNavigate();
  return (
    <div className="grid grid-cols-1 gap-10 md:grid-cols-1 container ml-auto mr-auto text-center font-sans mt-auto mb-auto">
      <Navbar></Navbar>
      <div className="container text-center font-sans md:w-1/2 md:ml-auto md:mr-auto mb-12 glassmorphism rounded-2xl mt-10">
        <Formik
          className="flex-none"
          initialValues={{
            name: updateCategory.catName,
            id: updateCategory.catId,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Kategori ismi girmelisiniz.";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            const token = Cookies.get("token");
            axios
              .put(
                `https://api.kahvecikolataistanbul.com/categories/${values.id}`,
                values,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                console.log(response.data, values);
                navigate("/update-category", { state: { needRefresh: true } });
              })
              .catch((err) => {
                if (err.response.status === 401) {
                  Cookies.remove("token");
                  Cookies.remove("isAuth");
                  navigate("/login");
                } else {
                  toast(err.response.data.statusText);
                }
              });

            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form
              className="text-form-title font-semibold text-lg"
              onSubmit={handleSubmit}
            >
              <div className="block mt-5 mb-5 text-form-title">
                <input
                  className="hidden"
                  type="text"
                  name="id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.id}
                />
                <label htmlFor="name">Kategori Güncelle</label>
                <input
                  className="block ml-auto mr-auto rounded-lg border-transparent flex-1 appearance-none border mt-5 mb-5 border-gray-300 w-11/12 py-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </div>
              {touched.name && errors.name && (
                <div className="font-extralight mt-2 left-[80px]  text-red-600">
                  {errors.name}
                </div>
              )}
              <button
                type="submit"
                disabled={isSubmitting}
                className="py-2 w-22 px-4 mb-5 bg-green-500 hover:bg-green-700 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
              >
                Güncelle
              </button>
            </form>
          )}
        </Formik>
      </div>

      <ToastContainer></ToastContainer>
    </div>
  );
}

export default UpdateCatForm;
