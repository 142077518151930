import React from "react";
import Item from "../components/Item";
import List from "../components/List";

function Home() {
  return (
    <div className="bg-hero-bg bg-contain md:bg-cover bg-center min-h-screen  ">
      <List></List>
      <Item></Item>
    </div>
  );
}

export default Home;
