import React from "react";

function Spinner({ text, color }) {
  color = color === undefined ? "blue" : color;
  return (
    <div className="text-center flex justify-center">
      <div
        className={`inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-${color}-500 motion-reduce:animate-[spin_1.5s_linear_infinite]`}
        role="status"
      ></div>
      <div className="ml-4 mt-1">{text}...</div>
    </div>
  );
}

export default Spinner;
