import "./style.css";
import { BrowserRouter } from "react-router-dom";
import { AuthWrapper } from "./context/AuthContext";
function App() {
  return (
    <div className="">
      <div className="App bg-ghost-white min-h-[100vh] ml-auto mr-auto">
        <BrowserRouter>
          <AuthWrapper />
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
