import React, { useState, useEffect } from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "../ui/Spinner";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
function UpdateCat() {
  let navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [categoryIsEmpty, setCategoryIsEmpty] = useState();
  const token = Cookies.get("token");
  //Categorileri listeleme işlemi
  const getCategories = () => {
    axios.get("https://api.kahvecikolataistanbul.com/categories").then(
      (response) => {
        if (response.data.statusText === "error")
          return alert(response.data.data);
        response.data.data.length < 1
          ? setCategoryIsEmpty(true)
          : setCategoryIsEmpty(false);
        setCategories(response.data.data);
      },
      (error) => {}
    );
    console.log("Test", categories);
  };

  //Categorileri sayfa renderlandığında listelemek için
  useEffect(() => {
    getCategories();
  }, []);

  //Kategorileri Silme işlemi
  const handleDelete = (id) => {
    axios
      .delete(`https://api.kahvecikolataistanbul.com/categories/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.statusText === "error")
          return alert(response.data.data);
        toast("Kategori Silindi.");
        getCategories();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          Cookies.remove("token");
          Cookies.remove("isAuth");
          navigate("/login");
        } else {
        }
      });
  };

  //Update işlemi burda navigate aracılığıyla update-cat-form'a gerekli stateleri yönlendiriyorum.
  const handleUpdate = (id, name) => {
    navigate("/update-cat-form", { state: { catId: id, catName: name } });
    getCategories();
  };

  return (
    <div>
      <div className="container md:w-4/5 ml-auto mr-auto p-5 md:grid-cols-2 gap-3 text-form-title glassmorphism rounded-2xl mt-10 mb-auto">
        {!categoryIsEmpty ? (
          <div className="">
            {categories.length > 0 ? (
              categories.map((cat, key) => (
                <div
                  key={key}
                  className="mt-5 mb-5 items-center md:flex	 rounded-xl bg-white shadow-custom p-5 border-0 justify-between"
                >
                  <div className="pointer overflow-wrap mb-2 mt-2">
                    <div className="mb-2 border-b-2">Kategori Numarası </div>
                    {cat.id}
                  </div>
                  <div className="pointer overflow-wrap mb-2 mt-2">
                    <div className="mb-2 border-b-2">Kategori İsmi </div>
                    {cat.name}
                  </div>
                  <div className="pointer flex gap-x-3 overflow-wrap ">
                    <button
                      type="button"
                      className="py-2 px-4 flex justify-center items-center bg-green-500 hover:bg-green-700 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                      onClick={() => handleUpdate(cat.id, cat.name)}
                    >
                      <BsFillPencilFill className="mr-2" />
                      Güncelle
                    </button>
                    <button
                      type="button"
                      className="py-2 px-4 flex justify-center items-center   bg-red-600 hover:bg-red-700 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                      onClick={() => handleDelete(cat.id)}
                    >
                      <MdDelete className="mr-2" />
                      Sil
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <Spinner text={"Yükleniyor"}></Spinner>
            )}
          </div>
        ) : (
          <div>
            Kategori yok,Lütfen Kategori Ekleyiniz <br /> <br />
            <button
              type="submit"
              onClick={() => navigate("/add-category")}
              className="py-2 w-32 px-4 mb-5 bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
            >
              Kategori Ekle
            </button>
          </div>
        )}
      </div>
      <ToastContainer></ToastContainer>
    </div>
  );
}

export default UpdateCat;
