import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { nav } from "./navigation";
import { AuthData } from "../context/AuthContext";
import Cookies from "js-cookie";
export const RenderRoutes = () => {
  const { user } = AuthData();
  const navigate = useNavigate();
  const isAuth = Cookies.get("isAuth");
  return (
    <Routes>
      {nav.map((r, i) => {
        if (r.isPrivate && isAuth) {
          return <Route key={i} path={r.path} element={r.element} />;
        } else if (!r.isPrivate) {
          return <Route key={i} path={r.path} element={r.element} />;
        } else if (!isAuth) return navigate("/login");
        else {
          return navigate("/login");
        }
      })}
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

export const RenderMenu = () => {
  const { user, logout } = AuthData();
  const isAuth = Cookies.get("isAuth");
  const MenuItem = ({ r }) => {
    return (
      <div className="menuItem">
        <Link to={r.path}>{r.name}</Link>
      </div>
    );
  };
  return (
    <div className="menu">
      {nav.map((r, i) => {
        if (!r.isPrivate && r.isMenu) {
          return <MenuItem key={i} r={r} />;
        } else if (isAuth && r.isMenu) {
          return <MenuItem key={i} r={r} />;
        } else return false;
      })}

      {user.isAuthenticated ? (
        <div className="menuItem">
          <Link to={"#"} onClick={logout}>
            Log out
          </Link>
        </div>
      ) : (
        <div className="menuItem">
          <Link to={"login"}>Log in</Link>
        </div>
      )}
    </div>
  );
};
