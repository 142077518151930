import React from "react";
import Navbar from "../components/Navbar";
import UpdateCat from "../components/UpdateCat";

function UpdateCategory() {
  return (
    <div className="grid grid-cols-1 p-2 gap-10 md:grid-cols-1 container ml-auto mr-auto text-center font-sans mt-auto mb-auto">
      <Navbar></Navbar>
      <UpdateCat></UpdateCat>
    </div>
  );
}

export default UpdateCategory;
