import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import Cookies from "js-cookie";
window.addEventListener("pagehide", (event) => {});
onpagehide = (event) => {
  Cookies.remove("token", "isAuth");
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <App />
  </>
);
