import Home from "../page/Home";
import Login from "../page/Login";
import Add from "../page/Add";
import UpdateForm from "../components/UpdateForm";
import UpdateItem from "../page/UpdateItem";
import AddCategory from "../page/AddCategory";
import UpdateCatForm from "../components/UpdateCatForm";
import UpdateCategory from "../page/UpdateCategory";

export const nav = [
  {
    path: "/",
    name: "Home",
    element: <Home />,
    isMenu: false,
    isPrivate: false,
  },
  {
    path: "/login",
    name: "Login",
    element: <Login />,
    isMenu: false,
    isPrivate: false,
  },
  {
    path: "/add",
    name: "Ürün Ekle",
    element: <Add />,
    isMenu: true,
    isPrivate: true,
  },
  {
    path: "/update",
    name: "Ürün Güncelle",
    element: <UpdateItem />,
    isMenu: true,
    isPrivate: true,
  },
  {
    path: "/add-category",
    name: "Kategori Ekle",
    element: <AddCategory />,
    isMenu: true,
    isPrivate: true,
  },
  {
    path: "/update-category",
    name: "Kategori Güncelle",
    element: <UpdateCategory />,
    isMenu: true,
    isPrivate: true,
  },
  {
    path: "/update-form",
    name: "UpdateForm",
    element: <UpdateForm />,
    isMenu: false,
    isPrivate: true,
  },
  {
    path: "/update-cat-form",
    name: "",
    element: <UpdateCatForm />,
    isMenu: false,
    isPrivate: true,
  },
];
