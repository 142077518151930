import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import axios from "axios";
import Navbar from "./Navbar";
import AddUpButton from "../ui/AddUpButton";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
function UpdateForm() {
  let location = useLocation();
  const updateItem = location.state;
  let navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const token = Cookies.get("token");
  useEffect(() => {
    axios.get("https://api.kahvecikolataistanbul.com/categories").then(
      (response) => {
        if (response.data.statusText === "error")
          return alert(response.data.data);
        setCategories(response.data.data);
      },
      (error) => {}
    );
  }, []);
  return (
    <div className="grid grid-cols-1 gap-10 md:grid-cols-1 container ml-auto mr-auto text-center font-sans mt-auto mb-auto">
      <Navbar />

      <div className="container mb-12 text-center font-sans glassmorphism rounded-2xl mt-10">
        <Formik
          className="flex-none"
          initialValues={{
            name: updateItem.mealName,
            id: updateItem.mealId,
            content: updateItem.mealContent,
            price: updateItem.mealPrice,
            category: updateItem.mealCatId,
            stok: updateItem.mealStok,
            file: null,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Yemek/içecek ismi gerekli";
            }
            if (!values.category) {
              errors.category = "Kategori ismi girmelisiniz.";
            }
            if (!values.price) {
              errors.price = "Ücret gerekli";
            } else if (!/^\d+$/.test(values.price)) {
              errors.price = "Geçersiz ücret";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setIsLoading(true);
            const formData = new FormData();
            formData.append("name", values.name);
            formData.append("content", values.content);
            formData.append("price", values.price);
            formData.append("category", values.category);
            formData.append("file", values.file);
            formData.append("stok", values.stok);
            axios
              .put(
                `https://api.kahvecikolataistanbul.com/products/${values.id}`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                console.log(response.data, values);
                setIsLoading(false);
                toast("Ürün Güncellendi.");
                navigate("/update", { state: { needRefresh: true } });
              })
              .catch((err) => {
                if (err.response.status === 401) {
                  Cookies.remove("token");
                  Cookies.remove("isAuth");
                  navigate("/login");
                } else {
                  toast(err.response.data.statusText);
                }
              });

            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form
              className="text-form-title font-semibold text-lg"
              onSubmit={handleSubmit}
            >
              <div className="block mt-5 mb-5 text-form-title">
                <input
                  className="hidden"
                  type="number"
                  name="id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.id}
                />
                <input
                  className="hidden"
                  type="number"
                  name="id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.stok}
                />
                <label htmlFor="name">Ürün İsmi</label>
                <input
                  className="block ml-auto mr-auto rounded-lg border-transparent flex-1 appearance-none border mt-5 mb-5 border-gray-300 w-11/12 py-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </div>
              <div className="block">
                <label htmlFor="content">Ürün İçeriği</label>
                <input
                  className="block ml-auto mr-auto rounded-lg border-transparent flex-1 appearance-none border mt-5 mb-5 border-gray-300 w-11/12 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  type="text"
                  name="content"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.content}
                />
              </div>
              <div className="block">
                <label htmlFor="price">Ücret</label>
                <input
                  className="block ml-auto mr-auto rounded-lg border-transparent flex-1 appearance-none border mt-5 mb-5 border-gray-300 w-11/12 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  type="number"
                  name="price"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.price}
                />
              </div>
              <div className="block">
                <label htmlFor="category">Kategori</label>
                <br></br>
                <select
                  className="text-gray-700 w-11/12 block px-3 py-2 ml-auto mr-auto bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                  value={values.category}
                  name="category"
                  id="category"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" label="Bir Seçenek seçiniz" />
                  {categories.map((item) => (
                    <option key={item.id} value={item.id} label={item.name} />
                  ))}
                </select>
              </div>
              <div className="block ml-auto mr-auto mt-5 mb-5">
                <label className="block" htmlFor="file">
                  Resim
                </label>
                <input
                  id="file"
                  name="file"
                  type="file"
                  accept="image/*"
                  onChange={(event) => {
                    setFieldValue("file", event.currentTarget.files[0]);
                  }}
                />
              </div>
              {!isLoading ? (
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="py-2 w-22 px-4 mb-5 bg-green-500 hover:bg-green-700 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                >
                  Güncelle
                </button>
              ) : (
                <AddUpButton text={"Güncelleniyor..."}></AddUpButton>
              )}
            </form>
          )}
        </Formik>
      </div>
      <ToastContainer></ToastContainer>
    </div>
  );
}

export default UpdateForm;
