import React from "react";
import Item from "./Item";

function List() {
  return (
    <div className="ml-auto text-center mr-auto">
      <h1 className="text-5xl text-white p-10">Kahve Çikolata Istanbul</h1>
    </div>
  );
}

export default List;
