import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import AddUpButton from "../ui/AddUpButton";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";
function AddMenu() {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());
  const navigate = useNavigate();
  useEffect(() => {
    axios.get(`https://api.kahvecikolataistanbul.com/categories`).then(
      (response) => {
        if (response.data.statusText === "error")
          return alert(response.data.data);
        setCategories(response.data.data);
      },
      (error) => {}
    );
  }, []);
  async function handleImageUpload(event, setFieldValue) {
    if (event.currentTarget.files.length) {
      const file = event.currentTarget.files[0]; // Kullanıcının seçtiği ilk dosyayı al
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options);
        setFieldValue("file", compressedFile); // Formik state'ini güncelle
      } catch (error) {
        console.error(error);
        toast.error("Resim sıkıştırma hatası");
      }
    }
  }
  return (
    <div>
      <div className="container text-form-title md:w-1/2 md:ml-auto md:mr-auto mb-12 glassmorphism rounded-2xl mt-10">
        <Formik
          className="flex-none"
          initialValues={{
            name: "",
            content: "",
            price: "",
            category: "",
            file: null,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Yemek/içecek ismi gerekli";
            }
            if (!values.category) {
              errors.category = "Kategori seçmelisiniz.";
            }
            if (!values.price) {
              errors.price = "Ücret gerekli";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
            const formData = new FormData();
            formData.append("name", values.name);
            formData.append("content", values.content);
            formData.append("price", values.price);
            formData.append("category", values.category);
            formData.append("file", values.file);
            setIsLoading(true);
            const token = Cookies.get("token");
            axios
              .post(
                `https://api.kahvecikolataistanbul.com/products`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                console.log(response.data.data);
                setIsLoading(false);
                resetForm();
                toast("Ürün Eklendi");
                setFieldValue("file", null);
                setInputKey(Date.now());
              })
              .catch((error) => {
                if (error.response.status === 401) {
                  Cookies.remove("token");
                  Cookies.remove("isAuth");
                  navigate("/login");
                } else {
                  toast(error.response.data.statusText);
                }
              });
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            clearField,
            setFieldValue,
          }) => (
            <form className=" font-semibold text-lg" onSubmit={handleSubmit}>
              <div className="block mt-5 mb-5 ">
                <label htmlFor="name">Ürün Ekle</label>
                <input
                  className="block ml-auto mr-auto rounded-lg border-transparent flex-1 appearance-none border mt-5 mb-5 border-gray-300 w-11/12 py-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                {touched.name && errors.name && (
                  <div className="font-extralight mt-2 mb-2 text-red-600">
                    {errors.name}
                  </div>
                )}
              </div>
              <div className="block">
                <label htmlFor="content">Ürün İçeriği</label>
                <input
                  className="block ml-auto mr-auto rounded-lg border-transparent  flex-1 appearance-none border mt-5 mb-5 border-gray-300 w-11/12 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  type="text"
                  name="content"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.content}
                />
                {touched.content && errors.content && (
                  <div className="font-extralight mt-2 mb-2 text-red-600">
                    {errors.content}
                  </div>
                )}
              </div>
              <div className="block">
                <label htmlFor="price">Ücret</label>
                <input
                  className="block ml-auto mr-auto rounded-lg border-transparent flex-1 appearance-none border mt-5 mb-5 border-gray-300 w-11/12 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  type="number"
                  name="price"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.price}
                />
                {touched.price && errors.price && (
                  <div className="font-extralight mt-2 mb-2 text-red-600">
                    {errors.price}
                  </div>
                )}
              </div>
              <div className="block">
                <label htmlFor="category mb-2">Kategori</label>
                <br></br>
                <br />
                <select
                  className="text-gray-700 w-11/12 block px-3 py-2 ml-auto mr-auto bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                  value={values.category}
                  name="category"
                  id="category"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" label="Bir Seçenek seçiniz" />
                  {categories.map((item) => (
                    <option key={item.id} value={item.id} label={item.name} />
                  ))}
                </select>
                {touched.category && errors.category && (
                  <div className="font-extralight mt-2 left-[80px] text-red-600">
                    {errors.category}
                  </div>
                )}
              </div>
              <div className="block ml-auto mr-auto mt-5 mb-5">
                <label className="block" htmlFor="file">
                  Resim
                </label>
                <input
                  id="file"
                  name="file"
                  type="file"
                  accept="image/*"
                  onChange={(event) => handleImageUpload(event, setFieldValue)}
                />
                {touched.file && errors.file && (
                  <div className="font-extralight mt-2 left-[80px]  text-red-600">
                    {errors.file}
                  </div>
                )}
              </div>
              {isLoading ? (
                <AddUpButton text={"Yükleniyor"}></AddUpButton>
              ) : (
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="py-2 w-20 px-4 mb-5 bg-red-600 text-white hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                >
                  Ekle
                </button>
              )}
            </form>
          )}
        </Formik>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default AddMenu;
