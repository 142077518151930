import { useContext, useEffect, useState } from "react";
import axios from "axios";
import Spinner from "../ui/Spinner";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import x from "../img/x.png";

function Item() {
  const [menu, setMenu] = useState([]);
  const [category, setCategory] = useState([]);
  useEffect(() => {
    axios.get("https://api.kahvecikolataistanbul.com/categories").then(
      (response) => {
        if (response.data.statusText === "error")
          return alert(response.data.data);
        setCategory(response.data.data);
      },
      (error) => {}
    );

    axios
      .get("https://api.kahvecikolataistanbul.com/menu")
      .then(
        (response) => {
          if (response.data.statusText === "error")
            return alert(response.data.data);
          var menuData = [];
          response.data.data.forEach((item, index) => {
            if (item.stok > 0) {
              const imgUrlArray = [
                response.data.data[index].img_path.split("/"),
              ];
              const imgUrl =
                "https://api.kahvecikolataistanbul.com/public/images/" +
                imgUrlArray[0][imgUrlArray[0].length - 1];
              var customItem = {
                ...item,
                img_url: imgUrl,
              };
              menuData.push(customItem);
            }
          });
          setMenu(menuData);
        },
        (error) => {}
      )
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className="container ml-auto mr-auto ">
      <div className=" text-white justify-center flex-wrap gap-3 p-5 md:grid-cols-5 ml-auto mr-auto">
        {menu.length > 0 ? (
          category.map((item, key) => {
            const mealsInCategory = menu.filter(
              (meal) => meal.categoryId === item.id
            );
            if (mealsInCategory.length > 0) {
              return (
                <ul>
                  <div
                    className="md:justify-center justify-center flex-wrap gap-3 p-5 md:grid-cols-5 ml-auto mr-auto"
                    key={key}
                  >
                    <li className="">
                      <details className="flex justify-center group items-center transition-all">
                        <summary className="flex items-center gap-3 px-4 py-3 font-medium marker:content-none hover:cursor-pointer border rounded-lg group-open:border-none border-gray-500">
                          <svg
                            class="w-5 h-5 text-yellow-400 transition-all group-open:rotate-90"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                            ></path>
                          </svg>
                          <div className="flex flex-col items-center">
                            <h2 className="text-4xl break-words font-bold text-center text-yellow-400  ">
                              {item.name}
                            </h2>
                          </div>
                        </summary>
                        <div className="flex justify-center flex-wrap transition-all">
                          {mealsInCategory.map((filteredMeal, index) => (
                            <div
                              key={index}
                              className="min-h-[425px] text-white text-center ml-5 mr-5 mt-5 mb-5 w-64 border rounded-lg border-white "
                            >
                              <div className="w-full p-5">
                                <img
                                  className="rounded-md h-[200px] w-full object-cover border-0"
                                  src={filteredMeal.img_url}
                                  loading="lazy"
                                  sizes="(max-width: 320px) 280px, (max-width: 480px) 440px, 800px"
                                  alt=""
                                />
                              </div>
                              <h2 className="text-white break-words px-2 text-center text-3xl">
                                {filteredMeal.name}
                              </h2>
                              <div className="mt-5 text-xs break-all px-2 h-8">
                                {filteredMeal.content}
                              </div>
                              <div className="mt-10 text-3xl">
                                {filteredMeal.price} TL
                              </div>
                            </div>
                          ))}
                        </div>
                      </details>
                    </li>
                  </div>
                </ul>
              );
            }
          })
        ) : (
          <Spinner text={"Menü Yükleniyor"} color={"yellow"}></Spinner>
        )}
      </div>
    </div>
  );
}

export default Item;
